const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://dumensapp.com' : 'http://192.168.100.106:3031',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://dumensapp.com' : 'http://192.168.100.106:3031',
    api: 'api/',
    apiSocket: 'dumens::1.2',
    nameDir: 'dumens',
    package: 'com.dumensapp.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyAy85qsBkCTaZdPth38_XrA80SgVvrL1P8',
    appName: 'Dumen’s',
    provider: '',
    colorPrimary: '#A60000',
    colorDark: '#1A1A1A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'com.dumensapp.passenger',
    playStoreDriverId: 'com.dumensapp.driver',
    appStorePassengerId: '6481042432',
    appStoreDriverId: '6481055496',
    email: "etstcarsac@gmail.com",
};
export default config;
